import API_ENDPOINT from '../globals/apiEndPoint';
import {reviewForm} from '../templates';
import '../components/ReviewCard';

const ReviewFormInitiator = {
  async init({reviewFormContainer, restaurantId}) {
    this._reviewFormContainer = reviewFormContainer;
    this._restaurantId = restaurantId;

    await this._renderForm();
  },

  async _renderForm() {
    this._reviewFormContainer.innerHTML = reviewForm();

    const errorElement = document.querySelector('#review-error');
    const inputName = document.querySelector('#reviewer');
    const inputReview = document.querySelector('#customer-review');

    inputName.addEventListener('input', () => {
      if (errorElement.innerHTML) {
        errorElement.innerHTML = '';
      }
    });

    inputReview.addEventListener('input', () => {
      if (errorElement.innerHTML) {
        errorElement.innerHTML = '';
      }
    });

    this._reviewFormContainer.addEventListener('submit', async (e) => {
      e.preventDefault();
      errorElement.innerHTML = '';

      const nameValue = inputName.value.trim();
      const reviewValue = inputReview.value.trim();
      const errors = [];

      if (!nameValue) {
        errors.push('Please type your name.');
      }
      if (!reviewValue) {
        errors.push('Please write your review.');
      }

      if (errors.length) {
        errorElement.innerHTML = `<div>
          <span><i class="fa fa-exclamation-triangle"></i></span>
          <span>${errors.map((error) => error).join('<br/>')}</span>
        </div>`;
      } else {
        try {
          const response = await fetch(API_ENDPOINT.POST,
              {
                method: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  id: this._restaurantId,
                  name: nameValue,
                  review: reviewValue,
                }),
              });

          const result = await response.json();
          if (!result.error) {
            inputName.value = '';
            inputReview.value = '';
            document
                .querySelector('#customer-review-list')
                .innerHTML = `${result.customerReviews.map((review) => {
                  return `<review-card
                        name="${review.name}"
                        date="${review.date}"
                        review="${review.review}"
                        ></review-card>`;
                }).join()}`;
          } else {
            errorElement.innerHTML = `<div>
              <span><i class="fa fa-exclamation-triangle"></i></span>
              <span>${result.message}</span>
            </div>`;
          }
        } catch (error) {
          errorElement.innerHTML = `<div>
            <span><i class="fa fa-exclamation-triangle"></i></span>
            <span>${error.message}</span>
          </div>`;
        }
      }
    });
  },
};

export default ReviewFormInitiator;
