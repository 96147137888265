const TopMenuInitiator = {
  init({button, topMenu, menuLink}) {
    button.addEventListener('click', (event) => {
      button.setAttribute('aria-expanded',
        button.getAttribute('aria-expanded') === 'true' ? 'false' : 'true');
      this._toggleTopMenu(event, topMenu);
    });
    menuLink.forEach((item, i) => {
      item.addEventListener('click', () => {
        if (topMenu.classList.contains('open')) {
          topMenu.classList.remove('open');
        }
      });
    });
  },

  _toggleTopMenu(event, topMenu) {
    event.stopPropagation();
    topMenu.classList.toggle('open');
  },
};

export default TopMenuInitiator;
