import CONFIG from './globals/config';

const reviewForm = () => `
    <h4>Add your review</h4>
    <div>
      <label for="reviewer">Name</label>
      <input
        id="reviewer"
        type="text"
        placeholder="Type your name here..."/>
    </div>
    <div>
      <label for="customer-review">Review</label>
      <textarea
        id="customer-review"
        placeholder="Type your review here..."
        rows="6"
      ></textarea>
    </div>
    <div id="review-error"></div>
    <button type="submit">Send review</button>
`;

const skeletonRestaurantItem = () => `
  <article id="article-skeleton">
    <div class="skeleton resto-image-skeleton">
      <span><i class="fa fa-map-marker"></i></span>
    </div>
    <div class="resto-info-skeleton">
      <h4 class="skeleton resto-info-skeleton-h4"></h4>
      <p class="resto-rating"
        style="--rating: 0;"
        aria-hidden="true"
        >
      </p>
      <p class="resto-desc-skeleton">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </p>
      <span href="#" class="btn-skeleton"></span>
    </div>
  </article>
`;

const restaurantItem = (resto) => `
  <article id="article-${resto.id}" class="restaurant-item">
    <div class="skeleton resto-image">
      <img
        class="lazyload"
        data-src="${CONFIG.BASE_IMAGE_URL_SMALL}${resto.pictureId}"
        alt="${resto.name}"
      />
      <span><i class="fa fa-map-marker"></i> ${resto.city}</span>
    </div>
    <div class="resto-info">
      <h4 class="resto__title">${resto.name || '-'}</h4>
      <p class="resto-rating"
        style="--rating: ${resto.rating};"
        aria-label="Rating of this restaurant is ${resto.rating} out of 5."
        role="img"
        >
      </p>
      <p class="resto-desc">${resto.description}</p>
      <a href="/#/detail/${resto.id}" class="btn btn-default">
        Read more <i class="fa fa-angle-right"></i>
      </a>
    </div>
  </article>
`;

const likeRestaurantButton = () => `
  <button id="like-button" class="like">
     <i
      class="fa fa-fw fa-thumbs-o-up"
      aria-hidden="true"
    ></i> Like this restaurant!
  </button>
`;

const unlikeRestaurantButton = () => `
  <button id="like-button" class="unlike">
    <i
      class="fa fa-fw fa-thumbs-up"
      aria-hidden="true"
    ></i> You like this restaurant
  </button>
`;

export {
  restaurantItem,
  skeletonRestaurantItem,
  reviewForm,
  likeRestaurantButton,
  unlikeRestaurantButton,
};
